var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Drawer from '../../common/components/Drawer';
import SwipeListener from '../../common/components/SwipeListener';
import { DisclaimerSubscription } from '../../common/event-bus/EventSubscription';
import { useCloseDrawer } from '../../common/hooks/drawer';
import Container from './Container';
import Content from './Content';
import Header from './Header';
var CommonDisclaimer = function (_a) {
    var toggleEvent = _a.toggleEvent;
    var closeDrawer = useCloseDrawer(toggleEvent && { toggleEvent: toggleEvent });
    return (React.createElement(DisclaimerSubscription, { toggleEvent: toggleEvent }, function (_a) {
        var value = _a.value;
        var closeAction = value.closeAction, open = value.open, title = value.title, content = value.content, p = value.p, html = value.html, ariaLabelledby = value.ariaLabelledby, width = value.width, withBackButton = value.withBackButton;
        var onClose = closeAction || closeDrawer;
        return (React.createElement(SwipeListener, { onSwipeRight: onClose },
            React.createElement(Drawer, __assign({ isOpen: open, onBackdropClick: onClose, width: width }, (ariaLabelledby && { ariaLabelledby: ariaLabelledby })),
                React.createElement(Container, null,
                    open && (React.createElement(Header, __assign({ onClick: onClose, title: title, withBackButton: withBackButton }, (ariaLabelledby && { id: ariaLabelledby })))),
                    React.createElement(Content, { p: p, html: html, content: content })))));
    }));
};
export default CommonDisclaimer;
